var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: "postPad boxset boxsetCatalog boxset-" + _vm.boxset.band },
    [
      _vm.lazyLoad
        ? _c("b-img-lazy", {
            staticClass: "boxsetThumbnail",
            attrs: { src: _vm.boxsetBasepath + _vm.boxset.image },
            on: {
              click: function ($event) {
                return _vm.purchaseBoxset(_vm.boxset)
              },
            },
          })
        : _c("img", {
            staticClass: "boxsetThumb",
            attrs: { src: _vm.boxsetBasepath + _vm.boxset.image },
            on: {
              click: function ($event) {
                return _vm.purchaseBoxset(_vm.boxset)
              },
            },
          }),
      _c(
        "span",
        { staticClass: "boxsetPrice" },
        [_c("SvgCart"), _vm._v(" Prezzo € " + _vm._s(_vm.boxset.public_price))],
        1
      ),
      _vm.mixinGetIntegrationInfo().sso
        ? _c(
            "div",
            [
              _vm.mixinGetIntegrationInfo().integration.availability >= 0 &&
              _vm.mixinGetIntegrationInfo().integration.availability <
                _vm.boxset.public_price
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.top",
                          value:
                            "Ci spiace! Il tuo attuale credito non è sufficiente ad acquistare questo voucher Salabam",
                          expression:
                            "'Ci spiace! Il tuo attuale credito non è sufficiente ad acquistare questo voucher Salabam'",
                          modifiers: { hover: true, top: true },
                        },
                      ],
                    },
                    [
                      _vm.mixinGetIntegrationInfo().integration.welfare == false
                        ? _c(
                            "b-button",
                            {
                              attrs: { variant: "secondary" },
                              on: {
                                click: function ($event) {
                                  return _vm.purchaseBoxset(_vm.boxset)
                                },
                              },
                            },
                            [_vm._v("\n                Scegli\n            ")]
                          )
                        : _c(
                            "b-button",
                            { attrs: { disabled: "", variant: "secondary" } },
                            [_vm._v("\n                Scegli\n            ")]
                          ),
                    ],
                    1
                  )
                : _c(
                    "b-button",
                    {
                      attrs: { variant: "secondary" },
                      on: {
                        click: function ($event) {
                          return _vm.purchaseBoxset(_vm.boxset)
                        },
                      },
                    },
                    [_vm._v("\n            Scegli\n        ")]
                  ),
            ],
            1
          )
        : _c(
            "b-button",
            {
              attrs: { variant: "secondary" },
              on: {
                click: function ($event) {
                  return _vm.purchaseBoxset(_vm.boxset)
                },
              },
            },
            [_vm._v("Scegli")]
          ),
      _c(
        "span",
        {
          directives: [
            {
              name: "b-modal",
              rawName: "v-b-modal",
              value: "modal-boxset-" + _vm.boxset.boxset_id,
              expression: "'modal-boxset-'+boxset.boxset_id",
            },
          ],
          staticClass: "scopriDisponibilita",
        },
        [_vm._v("Scopri le disponibilità")]
      ),
      _vm.boxset.trendingDestinations
        ? _c(
            "div",
            { staticClass: "panel-white boxsetCatalogTrendingDestinations" },
            [
              _c("SvgPin"),
              _c(
                "div",
                [
                  _c("p", [
                    _vm._v(
                      "\n                Alcune delle destinazioni più prenotate:\n            "
                    ),
                  ]),
                  _vm._l(
                    _vm.boxset.trendingDestinations,
                    function (destination, index) {
                      return _c(
                        "a",
                        {
                          key: index,
                          staticClass: "trendingDestionation",
                          on: {
                            click: function ($event) {
                              return _vm.setTrendingDestination(
                                destination,
                                _vm.boxset
                              )
                            },
                          },
                        },
                        [_vm._v(_vm._s(destination.label))]
                      )
                    }
                  ),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-boxset-" + _vm.boxset.boxset_id,
            size: "md",
            "hide-footer": "",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function () {
                return [
                  _vm._v("\n            Scopri le disponibilità\n        "),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "b-row",
            [
              _c("b-col", [
                _c(
                  "div",
                  { staticClass: "boxsetCatalogModal" },
                  [
                    _c("SearchBar", {
                      attrs: {
                        loading: false,
                        band: _vm.boxset.band,
                        focus: _vm.boxset.focus,
                        geo: {},
                        dates: { nights: parseInt(_vm.boxset.nights) },
                        occupancy: {},
                        hasResults: false,
                        datePickerSelector:
                          "datepicker-scopri-disponibilita-" + _vm._uid,
                      },
                      on: { "submit-search": _vm.submitSearch },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }