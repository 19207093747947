var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bestSellers" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "px-0 pr-lg-3", attrs: { sm: "12", lg: "6" } },
            [
              !_vm.bestSellersBoxImageUrl
                ? _c("div", {
                    staticClass: "bestSellerSplash",
                    style: {
                      "background-image":
                        "url('" + require("@/assets/best-sellers.jpg") + "')",
                    },
                  })
                : _c("div", {
                    staticClass: "bestSellerSplash",
                    style: {
                      "background-image":
                        "url('" + _vm.bestSellersBoxImageUrl + "')",
                    },
                  }),
              _c("div", { staticClass: "bestSellerBlackBox" }, [
                _c("h4", { staticClass: "boxTitle" }, [
                  _vm._v(_vm._s(_vm.bestSellersBoxTitle)),
                ]),
                _vm.bestSellersBoxText == "usa-componente-copy-scadenza"
                  ? _c(
                      "p",
                      { staticClass: "boxText" },
                      [
                        _c("CopyScadenza", {
                          attrs: {
                            default:
                              "Scegli uno dei nostri voucher per viaggiare e praticamente prolunghi il valore del tuo credito di {value} mesi!",
                            promoMonths:
                              "Scegli uno dei nostri voucher per viaggiare e praticamente prolunghi il valore del tuo credito di {value} mesi!",
                            promoFixed:
                              "Scegli uno dei nostri voucher per viaggiare e praticamente prolunghi il valore del tuo credito fino al {value}!",
                          },
                        }),
                      ],
                      1
                    )
                  : _c("p", {
                      staticClass: "boxText",
                      domProps: { innerHTML: _vm._s(_vm.bestSellersBoxText) },
                    }),
                Object.keys(_vm.bestSellersCta).length > 0
                  ? _c("div", { staticClass: "bestSellersCta" }, [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: {
                            href: _vm.bestSellersCta.href,
                            target: _vm.bestSellersCta.target,
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.bestSellersCta.a) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]
          ),
          _c(
            "b-col",
            { staticClass: "px-2 px-lg-0", attrs: { sm: "12", lg: "6" } },
            [
              _c(
                "p",
                {
                  staticClass:
                    "mt-4 mt-lg-0 mb-4 ml-lg-3 text-center text-lg-left",
                },
                [_c("strong", [_vm._v("I Salabam più venduti")])]
              ),
              _c(
                "div",
                { staticClass: "slick" },
                [
                  _c(
                    "Slick",
                    {
                      ref: "slickBestSellers",
                      attrs: { options: _vm.slickOptions },
                    },
                    _vm._l(_vm.bestSellers, function (boxset, index) {
                      return _c(
                        "div",
                        { key: "slide-" + index },
                        [
                          _c("BoxsetCatalog", {
                            attrs: {
                              boxset: boxset,
                              lazyLoad: false,
                              trackClickLabel: "bestSellerClick",
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _c("span", { attrs: { id: "slickBestsellerPrev" } }, [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "60",
                          height: "60",
                          viewBox: "0 0 60 60",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            opacity: "0.6",
                            d: "M30 59.9992C46.5685 59.9992 60 46.5681 60 29.9996C60 13.4311 46.5685 0 30 0C13.4315 0 0 13.4311 0 29.9996C0 46.5681 13.4315 59.9992 30 59.9992Z",
                            fill: "white",
                          },
                        }),
                        _c("path", {
                          attrs: {
                            d: "M33.5758 43.5237L20.2078 30.0001L33.5758 16.4766",
                            stroke: "#333333",
                            "stroke-width": "2",
                            "stroke-miterlimit": "10",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                          },
                        }),
                      ]
                    ),
                  ]),
                  _c("span", { attrs: { id: "slickBestSellersNext" } }, [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "60",
                          height: "60",
                          viewBox: "0 0 60 60",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            opacity: "0.6",
                            d: "M30 59.9992C46.5685 59.9992 60 46.5681 60 29.9996C60 13.4311 46.5685 0 30 0C13.4315 0 0 13.4311 0 29.9996C0 46.5681 13.4315 59.9992 30 59.9992Z",
                            fill: "white",
                          },
                        }),
                        _c("path", {
                          attrs: {
                            d: "M33.5758 43.5237L20.2078 30.0001L33.5758 16.4766",
                            stroke: "#333333",
                            "stroke-width": "2",
                            "stroke-miterlimit": "10",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                          },
                        }),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }