var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "searchBoxset" } }, [
    _c(
      "div",
      { staticClass: "boxsetFormItem", attrs: { id: "search-boxset-focus" } },
      [
        _c(
          "div",
          { staticClass: "input-with-icon" },
          [
            _c("label", [_vm._v("Dove vuoi andare?")]),
            _c(
              "b-form-select",
              {
                model: {
                  value: _vm.searchFocus,
                  callback: function ($$v) {
                    _vm.searchFocus = $$v
                  },
                  expression: "searchFocus",
                },
              },
              [
                _c(
                  "b-form-select-option",
                  { attrs: { value: null, disabled: "" } },
                  [_vm._v("Scegli")]
                ),
                _vm._l(
                  this.$config.guiSettings.searchFocus,
                  function (value, index) {
                    return _c(
                      "b-form-select-option",
                      { key: index, attrs: { value: index } },
                      [_vm._v(_vm._s(value))]
                    )
                  }
                ),
              ],
              2
            ),
          ],
          1
        ),
      ]
    ),
    _vm.mixinGetIntegrationInfo(0).sso
      ? _c(
          "div",
          {
            staticClass: "boxsetFormItem",
            attrs: { id: "search-boxset-welfare" },
          },
          [
            _c(
              "div",
              { staticClass: "input-with-icon" },
              [
                _c("SvgInfoCircle", {
                  directives: [
                    {
                      name: "b-popover",
                      rawName: "v-b-popover.hover.top",
                      value:
                        "Attivando il pulsante ti mostreremo solamente vouchers che rientrano nella disponibilità del tuo credito welfare",
                      expression:
                        "'Attivando il pulsante ti mostreremo solamente vouchers che rientrano nella disponibilità del tuo credito welfare'",
                      modifiers: { hover: true, top: true },
                    },
                  ],
                  staticClass: "svgInfo cursor-pointer",
                }),
                _c("label", [_vm._v("Mostra in base al credito welfare")]),
                _c("CustomSwitch", {
                  attrs: {
                    size: "sm",
                    status: _vm.searchShowWelfare,
                    switchUniqueKey: "custom-switch-hero",
                  },
                  on: { "switch-change": _vm.toggleShowWelfare },
                }),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "boxsetFormItem", attrs: { id: "search-boxset-budget" } },
      [
        _c("div", { staticClass: "input-with-icon" }, [
          _c("label", [_vm._v("Quanto vuoi spendere?")]),
          !_vm.searchShowWelfare
            ? _c(
                "div",
                [
                  _c(
                    "b-form-select",
                    {
                      attrs: { disabled: _vm.searchShowWelfare },
                      model: {
                        value: _vm.searchBudget,
                        callback: function ($$v) {
                          _vm.searchBudget = $$v
                        },
                        expression: "searchBudget",
                      },
                    },
                    [
                      _c(
                        "b-form-select-option",
                        { attrs: { value: null, disabled: "" } },
                        [_vm._v("budget")]
                      ),
                      _vm._l(_vm.budgetOptions, function (value, index) {
                        return _c(
                          "b-form-select-option",
                          {
                            key: index,
                            attrs: { value: value.min + "-" + value.max },
                          },
                          [
                            value.min == 0 && value.max == 0
                              ? _c("span", [_vm._v("Mostra tutti i voucher")])
                              : value.max == 0
                              ? _c("span", [
                                  _vm._v("da " + _vm._s(value.min) + " in su"),
                                ])
                              : _c("span", [
                                  _vm._v(
                                    "da " +
                                      _vm._s(value.min) +
                                      " a " +
                                      _vm._s(value.max)
                                  ),
                                ]),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            : _c(
                "div",
                [
                  _c("b-form-input", {
                    attrs: { disabled: true },
                    model: {
                      value: _vm.availabilityText,
                      callback: function ($$v) {
                        _vm.availabilityText = $$v
                      },
                      expression: "availabilityText",
                    },
                  }),
                ],
                1
              ),
        ]),
      ]
    ),
    _c(
      "div",
      { staticClass: "boxsetFormItem", attrs: { id: "search-boxset-buttons" } },
      [
        _c(
          "b-button",
          {
            staticClass: "btn-search",
            attrs: {
              disabled: _vm.disableSearchButton(),
              variant: "secondary",
            },
            on: {
              click: function ($event) {
                return _vm.submitSearch()
              },
            },
          },
          [_vm._v("Cerca\n        ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }