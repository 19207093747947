<template>
    <div class="bestSellers">
        <b-row>
            <b-col sm="12" lg="6" class="px-0 pr-lg-3">
                <div v-if="!bestSellersBoxImageUrl" class="bestSellerSplash" v-bind:style="{ 'background-image': 'url(\'' + require('@/assets/best-sellers.jpg') + '\')' }"></div>
                <div v-else class="bestSellerSplash" v-bind:style="{ 'background-image': 'url(\'' + bestSellersBoxImageUrl + '\')' }"></div>

                <div class="bestSellerBlackBox">
                    <h4 class="boxTitle">{{bestSellersBoxTitle}}</h4>
                    <p class="boxText" v-if="bestSellersBoxText == 'usa-componente-copy-scadenza'">
                        <CopyScadenza
                            default="Scegli uno dei nostri voucher per viaggiare e praticamente prolunghi il valore del tuo credito di {value} mesi!"
                            promoMonths="Scegli uno dei nostri voucher per viaggiare e praticamente prolunghi il valore del tuo credito di {value} mesi!"
                            promoFixed="Scegli uno dei nostri voucher per viaggiare e praticamente prolunghi il valore del tuo credito fino al {value}!"
                        />                        
                    </p>
                    <p class="boxText" v-else v-html="bestSellersBoxText">

                    </p>
                    <div class="bestSellersCta" v-if="Object.keys(bestSellersCta).length > 0">
                        <a class="btn btn-secondary" :href="bestSellersCta.href" :target="bestSellersCta.target">
                            {{bestSellersCta.a}}
                        </a>
                    </div>
                </div>
            </b-col>
            <b-col sm="12" lg="6" class="px-2 px-lg-0">
                <p class="mt-4 mt-lg-0 mb-4 ml-lg-3 text-center text-lg-left"><strong>I Salabam più venduti</strong></p>
                <div class="slick">
                    <Slick ref="slickBestSellers" :options="slickOptions">
                        <div v-for="(boxset,index) in bestSellers" :key="'slide-'+index">
                            <BoxsetCatalog :boxset="boxset" :lazyLoad="false" :trackClickLabel="'bestSellerClick'"/>
                        </div>
                    </Slick>
                    <span id="slickBestsellerPrev">
                        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.6" d="M30 59.9992C46.5685 59.9992 60 46.5681 60 29.9996C60 13.4311 46.5685 0 30 0C13.4315 0 0 13.4311 0 29.9996C0 46.5681 13.4315 59.9992 30 59.9992Z" fill="white"/>
                        <path d="M33.5758 43.5237L20.2078 30.0001L33.5758 16.4766" stroke="#333333" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </span>
                    <span id="slickBestSellersNext">
                        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.6" d="M30 59.9992C46.5685 59.9992 60 46.5681 60 29.9996C60 13.4311 46.5685 0 30 0C13.4315 0 0 13.4311 0 29.9996C0 46.5681 13.4315 59.9992 30 59.9992Z" fill="white"/>
                        <path d="M33.5758 43.5237L20.2078 30.0001L33.5758 16.4766" stroke="#333333" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </span>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import Slick from 'vue-slick'
    import BoxsetCatalog from '../blocks/boxset/boxset-catalog'
    import CopyScadenza from '@/components/blocks/common/copy-scadenza'

    export default {
        name: 'best-seller',
        props:
        {
        },
        components:
        {
            Slick,
            BoxsetCatalog,
            CopyScadenza,
        },
        data(){
            return {
                bestSellers: this.$config.bestSellersBoxsets,
                bestSellersBoxTitle: this.$config.guiSettings.bestSellers.boxTitle,
                bestSellersBoxText: this.$config.guiSettings.bestSellers.boxText,
                bestSellersBoxImageUrl: this.$config.guiSettings.bestSellers.boxImageUrl,
                bestSellersCta: (this.$config.guiSettings.bestSellers.cta !== undefined) ? this.$config.guiSettings.bestSellers.cta : {},
                slickOptions: {
                    autoplay: (this.$config.guiSettings.autoplaySpeedBestSellers != 0),
                    autoplaySpeed: this.$config.guiSettings.autoplaySpeedBestSellers,
                    slidesToShow: 3,
                    prevArrow: '#slickBestsellerPrev',
                    nextArrow: '#slickBestSellersNext',
                    adaptiveHeight:true,
                    responsive: [
                        {
                            breakpoint: 1600,
                            settings: {
                                centerMode: false,
                                slidesToShow: 2,
                                slidesToScroll: 1,
                                infinite: true,
                            }
                        },
                        // {
                        //     breakpoint: 1200,
                        //     settings: {
                        //         //adaptiveHeight:false,
                        //         centerMode: false,
                        //         slidesToShow: 2,
                        //         slidesToScroll: 1,
                        //         infinite: true,
                        //     }
                        // },
                        {
                            breakpoint: 992,
                            settings: {
                                centerMode: false,
                                slidesToShow: 3,
                                slidesToScroll: 1,
                                infinite: true,
                            }
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                centerMode: false,
                                slidesToShow: 2,
                                slidesToScroll: 1,
                                infinite: true,
                            }
                        },
                        {
                            breakpoint: 470,
                            settings: {
                                centerMode: false,
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                infinite: true,
                            }
                        }
                    ]
                },
            }
        },
        mounted()
        {
        },
        methods:
        {
        }
    }
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../scss/_custom.scss';

    .bestSellers
    {
        .bestSellerSplash
        {
            width:100%;
            height:100%;
            background-size:cover;
            background-position: center center;
            position:relative;

            @include media-breakpoint-down(md) {
                min-height: 350px;
            }
        }

        .bestSellerBlackBox
        {
            position:absolute;
            top:50%;
            transform:translateY(-50%);
            max-height:calc(100% - 2rem);
            width:75%;
            max-width: 350px;
            background:rgba($dark-blue,0.8);
            left:$grid-gutter-width;
            padding:$grid-gutter-width/2;
            .boxTitle
            {
                color:$white;
            }
            .boxText
            {
                color:$white;
                margin-bottom:0;
            }
            @include media-breakpoint-down(xs)
            {
                left:0;
                right:0;
                margin:0 auto;
            }
        }

        .slick
        {
            position:relative;
        
            #slickBestsellerPrev,
            #slickBestSellersNext {
                position:absolute;
                top:50%;
                width:32px;
                height:32px;
                text-align:center;
                border-radius: 50%;
                background:rgba($white,0.9);
                display:flex;
                justify-content: center;
                align-items: center;
                transform: translateY(-50%);
                cursor:pointer;

                &#slickBestsellerPrev {
                    left:0;
                    
                }
                &#slickBestSellersNext {
                    right:0;
                    
                    svg{
                        transform:rotate(180deg);
                    }
                }

                @include media-breakpoint-down(xs)
                {
                    transform:none !important;
                }

                @include media-breakpoint-up(md)
                {
                    width:38px;
                    height:38px;
                }
            }
            .slick-slider
            {
                position:relative;
                margin: 0 $grid-gutter-width/2;
            } 
            .slick-slide
            {
                width:280px;
                padding:0 1rem 0;
                img
                {
                    max-width: 100%;
                    //border-radius:$border-radius-medium;
                    //box-shadow:$box-shadow;
                    margin-bottom:1rem;
                }   
            }          
        }
        .slick-slide
        {
            cursor:pointer;
        }
        .boxsetInfo
        {
            display:flex;
            &>svg
            {
                width:40px;
                margin-right:1rem;
            }
        }
        .boxsetText
        {
            color:$black;
            font-size:12px;
            .boxsetTitle
            {
                display:block;
                font-weight: 600;
                font-size: 19px;
                margin-top: 12px;
            }
        }
        .boxsetPrice
        {
            /*
            display:flex;
            align-items:center;
            text-align:left;
            margin-top:4px;
            svg
            {
                margin-right:8px;
            }
            *
            {
                font-size: 13px;
                font-weight: bold;
            }
            */
            svg
            {
                margin-right:4px;
                path
                {
                    stroke:$primary;
                    fill:none;
                }
            }
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin: 15px 0;
            font-size: 16px;
            font-weight: bold;
            color: $primary;      
        }
        button
        {
            display:block;
            width:100%;
            margin-bottom:1rem;
        }
        .bestSellersCta
        {
            margin-top:1rem;
            .btn
            {
                box-shadow:none;
            }
        }     
    }
</style>