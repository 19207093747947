<template>
    <div :class="'postPad boxset boxsetCatalog boxset-'+boxset.band">
        
        <b-img-lazy v-if="lazyLoad" :src="boxsetBasepath + boxset.image" class="boxsetThumbnail" @click="purchaseBoxset(boxset)"/>
        <img v-else class="boxsetThumb" :src="boxsetBasepath + boxset.image" @click="purchaseBoxset(boxset)"/>

      <span class="boxsetPrice"><SvgCart /> Prezzo € {{boxset.public_price}}</span>

        <div v-if="mixinGetIntegrationInfo().sso">
            <div v-if="mixinGetIntegrationInfo().integration.availability >= 0 && mixinGetIntegrationInfo().integration.availability < boxset.public_price" v-b-popover.hover.top="'Ci spiace! Il tuo attuale credito non è sufficiente ad acquistare questo voucher Salabam'">
                <!-- Aggiungo check su flag welfare: https://trello.com/c/y82xQvzL/884-flag-welfare-fix-welfare-false-acquisto-oltre-credito-->
                <b-button @click="purchaseBoxset(boxset)" variant="secondary" v-if="mixinGetIntegrationInfo().integration.welfare == false">
                    Scegli
                </b-button>
                <b-button disabled variant="secondary" v-else>
                    Scegli
                </b-button>
            </div>
            <b-button @click="purchaseBoxset(boxset)" variant="secondary" v-else>
                Scegli
            </b-button>
        </div>

        <b-button v-else @click="purchaseBoxset(boxset)" variant="secondary">Scegli</b-button>

        <span v-b-modal="'modal-boxset-'+boxset.boxset_id" class="scopriDisponibilita">Scopri le disponibilità</span>

        <div v-if="boxset.trendingDestinations" class="panel-white boxsetCatalogTrendingDestinations" >
            <SvgPin />
            <div>
                <p>
                    Alcune delle destinazioni più prenotate:
                </p>
                <a class="trendingDestionation" v-for="(destination,index) in boxset.trendingDestinations" v-bind:key="index" @click="setTrendingDestination(destination, boxset)">{{destination.label}}</a>
            </div>
        </div>

        <b-modal :id="'modal-boxset-'+boxset.boxset_id" size="md" hide-footer>
            <template v-slot:modal-title>
                Scopri le disponibilità
            </template>
            <b-row>
                <b-col>
                    <div class="boxsetCatalogModal">
                        <SearchBar
                            :loading="false"
                            :band="boxset.band"
                            :focus="boxset.focus"
                            :geo="{}"
                            :dates="{nights: parseInt(boxset.nights)}"
                            :occupancy="{}"
                            :hasResults="false"
                            :datePickerSelector="'datepicker-scopri-disponibilita-'+_uid"
                            v-on:submit-search="submitSearch"
                        />
                    </div>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import SearchBar from '../search-bar'
import SvgCart from './../../svg/cart'
import SvgPin from './../../svg/pin'
export default {
    name: 'boxset-catalog',
    components:
    {
        SvgCart,
        SvgPin,
        SearchBar,
    },
    props:
    {
        boxset: Object,
        lazyLoad: {
          type: Boolean,
          default: true,
        },
        trackClickLabel: {
          // label per evento analytics/logrocket
          type: String,
          default: '',
        },
    },
    data()
    {
        return {
            boxsetBasepath: this.$config.boxsets_images_basepath,
        }
    },
    beforeMount()
    {
        this.boxset.public_price = parseFloat(this.boxset.public_price)
    },
    mounted()
    {
    },
    methods:
    {
        setTrendingDestination(destination, boxset)
        {

            this.mixinSendMutation('setActiveBand', boxset.band)
            this.mixinSendMutation('setNights', boxset.nights)

            this.mixinSendMutation('setGeo',{
                latitude: destination.lat,
                longitude: destination.lng,
                matchLevel: destination.matchLevel,
                label: destination.label
            })
            this.submitSearch()
        },
        purchaseBoxset(boxset)
        {

            if(this.trackClickLabel != ''){

              if(this.$gtm) this.$gtm.trackEvent({
                event: 'customEvent',
                category: 'userBehaviour',
                action: this.trackClickLabel,
                label: boxset.boxset_title,
                value: boxset.public_price,
              })

              if(window.LogRocket) window.LogRocket.track(this.trackClickLabel)

            }


            this.mixinSendMutation('setFocus',boxset.focus)
            this.mixinSendMutation('setActiveBand',boxset.band)
            this.mixinSendMutation('setNights',boxset.nights)
            this.mixinBoxsetSelected(boxset)

            if(process.env.VUE_APP_MODE != 'production') window.console.log('boxset-catalog.vue click SCEGLI')


            if(this.mixinGetIntegrationInfo().sso){
              // gli autenticati vanno al checkout (in quanto hanno già un "pagamento" definito, integrazione, codice)
              this.mixinGoTo('checkout', {})

            }else{

              // gli altri alla pagina di disambiguazione (che riporta le opzioni di pagamento)
              this.mixinGoTo('before-checkout', {})

            }





        },
        submitSearch()
        {
            this.mixinSendMutation('setTriggerSearch',true)
            this.mixinSendMutation('setActiveBand',this.boxset.band)
            this.mixinGoTo('search',{})
        }
    }
}
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../../scss/_custom.scss';

    .boxsetCatalog
    {
        .scopriDisponibilita
        {
            font-weight: bold;
            font-size: 0.875rem;
        }
        .boxsetCatalogTrendingDestinations
        {
            font-size: 13px;
            display:flex;
            padding:1rem;
            p
            {
                flex:1;
                margin-bottom:0;
            }
            svg
            {
                min-width:11px;
                margin-right: 6px;
                margin-top:3px
            }
            svg *
            {
                fill:$white;
                stroke:$black;
            }
        }
        .trendingDestionation
        {
            color:$secondary;
            text-decoration:underline;
            position:relative;
            cursor:pointer;
            &:hover
            {
                text-decoration:underline;
            }
            &:not(:last-child)
            {
                @include media-breakpoint-down(lg)
                {
                    &:after
                    {
                        content:", ";
                        text-decoration:none;
                        margin-right:3px;
                        display:inline-block;
                    }
                }
                @include media-breakpoint-up(xl)
                {
                    padding-right:8px;
                    margin-right:8px;
                    white-space:nowrap;
                    &:after
                    {
                        content:"";
                        position:absolute;
                        top:60%;
                        transform:translateY(-50%);
                        right:0;
                        width:1px;
                        height:14px;
                        background:$secondary;
                    }
                }
            }
        }
    }

    .modal-header
    {
        margin-bottom:0 !important;
    }

    .boxsetCatalogModal
    {
        #searchBar
        {
            padding:$grid-gutter-width/4 $grid-gutter-width/2;
            box-shadow:none !important;
        }
        .userBox,
        .breadcrumbs,
        #search-form-filters
        {
            display:none !important;
        }
        #search-form-buttons
        {
            padding-right:0 !important;
        }

        #search-form
        {
            padding-right:0 !important;
            form
            {
                display:flex;
                flex-direction:column;
                .search-form-item
                {
                    flex:100% !important;
                    margin-right:0 !important;
                    padding-right:0 !important;
                    min-width: 100% !important;
                    &:not(#search-form-buttons)
                    {
                        margin-bottom:1rem !important;
                    }
                    &#search-form-buttons
                    {
                        margin-top:1.25rem !important;
                    }
                }
            }
        }

        //centrare calendario aperto per evitare mese tagliato
        #search-form
        {
            #search-form-datepicker,
            .datepicker-fields
            {
                position:static !important;
            }
            .asd__wrapper--datepicker-open
            {
                top: 50% !important;
                left: 50% !important;
                transform: translateY(-50%) translateX(-50%) !important;
            }
        }
    }
</style>