<template>
    <div id="searchBoxset">

        <div id="search-boxset-focus" class="boxsetFormItem">
            <div class="input-with-icon">
                <label>Dove vuoi andare?</label>
                <b-form-select v-model="searchFocus">
                    <b-form-select-option :value="null" disabled>Scegli</b-form-select-option>
                    <b-form-select-option v-for="(value,index) in this.$config.guiSettings.searchFocus" v-bind:key="index" :value="index">{{value}}</b-form-select-option>
                </b-form-select>
            </div>
        </div>

        <div id="search-boxset-welfare" class="boxsetFormItem" v-if="mixinGetIntegrationInfo(0).sso">
          <div class="input-with-icon">
            <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'Attivando il pulsante ti mostreremo solamente vouchers che rientrano nella disponibilità del tuo credito welfare'" />
            <label>Mostra in base al credito welfare</label>
            <CustomSwitch size="sm" :status="searchShowWelfare" :switchUniqueKey="'custom-switch-hero'" v-on:switch-change="toggleShowWelfare"/>
          </div>
        </div>

        <div id="search-boxset-budget" class="boxsetFormItem">
            <div class="input-with-icon">

              <label>Quanto vuoi spendere?</label>

              <div v-if="!searchShowWelfare">

                <b-form-select v-model="searchBudget" :disabled="searchShowWelfare">
                    <b-form-select-option :value="null" disabled>budget</b-form-select-option>
                    <b-form-select-option v-for="(value,index) in budgetOptions" v-bind:key="index" :value="value.min+'-'+value.max">
                        <span v-if="value.min==0 && value.max==0">Mostra tutti i voucher</span>
                        <span v-else-if="value.max==0">da {{value.min}} in su</span>
                        <span v-else>da {{value.min}} a {{value.max}}</span>
                    </b-form-select-option>
                </b-form-select>
              </div>

              <div v-else>
                <b-form-input v-model="availabilityText" :disabled="true"></b-form-input>
              </div>

            </div>
        </div>

        <!--<div id="search-boxset-nights" class="boxsetFormItem">
            <div class="input-with-icon">
                <label>Numero notti</label>
                <b-form-select v-model="searchNights" :options="this.$config.guiSettings.searchNights">
                    <b-form-select-option :value="0" disabled>Notti</b-form-select-option>
                </b-form-select>
            </div>
        </div>-->

        <div id="search-boxset-buttons" class="boxsetFormItem">
            <b-button
                class="btn-search"
                :disabled="disableSearchButton()"
                variant="secondary"
                v-on:click="submitSearch()"
            >Cerca
            </b-button>
        </div>

    </div>
</template>

<script>
import Vue from 'vue'
import CustomSwitch from './../../atoms/custom-switch'
import SvgInfoCircle from '@/components/svg/info-circle'

export default {
    name: 'search-boxset',
    components:
    {
        CustomSwitch,
        SvgInfoCircle,
    },
    props:{
        focus: {
            type: String,
            default: '-'
        },
        budget:{
            type: String,
            default: '0-0',
        },
        nights: {
            type: Number,
            default: 0,
        },
        showWelfare:
        {
            type: Boolean,
            default: false,
        },
        triggerSearch: Boolean,
    },
    computed:
    {
      searchFocus: {
        get() { return (this.$store.state.focus)? this.$store.state.focus : '-' },
        set(value) { this.mixinSendMutation('setFocus',value) }
      },
      searchBudget: {
        get() { return (this.$store.state.budget)? this.$store.state.budget : '0-0'},
        set(value) { this.mixinSendMutation('setBudget',value) }
      },
      searchNights: {
        get() { return this.$store.state.dates.nights },
        set(value) { this.mixinSendMutation('setNights',value) }
      },
      searchShowWelfare: {
        get() {


          // l'if "resetta" showWelfare quando resta true da una precedente sessione
          // ma poi si torna "non integrati"
          if(
                 this.$store.state.showWelfare

              && (
                     !Vue.prototype.$config.integration
                  || !Vue.prototype.$config.integration.availability
                  || Vue.prototype.$config.integration.availability < 75 // cofanetto di minor importo
              )

          ){

            this.mixinSendMutation('setShowWelfare', false)
            this.mixinSendMutation('setBudget', '0-0')

          }

          return this.$store.state.showWelfare

        },
        set(value) { this.mixinSendMutation('setShowWelfare',value) }
      },
    },

    data() {
        return {
            budgetOptions: Vue.prototype.$config.guiSettings.searchPriceranges,
            availabilityText: (Vue.prototype.$config.integration)? 'da 0 a ' + Vue.prototype.$config.integration.availability : 'nessuna disponibilità', // nessuna disponibilità non sarà mai mostrato
        }
    },
    methods:
    {
        toggleShowWelfare()
        {
            this.searchShowWelfare = !this.searchShowWelfare

            if(process.env.VUE_APP_MODE != 'production') window.console.log(this.searchShowWelfare)

            this.updateBudgetOptions()


        },
        updateBudgetOptions() {

          if(process.env.VUE_APP_MODE != 'production') window.console.log('updateBudgetOptions')

          if(process.env.VUE_APP_MODE != 'production') window.console.log(this.searchShowWelfare)

          if(
              this.searchShowWelfare
              && Vue.prototype.$config.integration
              && Vue.prototype.$config.integration.availability
              && Vue.prototype.$config.integration.availability > 0
          ){

            if(process.env.VUE_APP_MODE != 'production') window.console.log('updateBudgetOptions dentro if')

            this.budgetOptions = [
              {min: 0, max: Vue.prototype.$config.integration.availability}
            ]

            this.searchBudget = '0-' + Vue.prototype.$config.integration.availability
            this.mixinSendMutation('setBudget', this.searchBudget)

          }else{

            if(process.env.VUE_APP_MODE != 'production') window.console.log('else')

            this.budgetOptions = Vue.prototype.$config.guiSettings.searchPriceranges
            this.searchBudget = '0-0'
            this.mixinSendMutation('setBudget', this.searchBudget)

          }
        },
        submitSearch()
        {

          let parameters = {
                focus: this.searchFocus,
                budget: this.searchBudget,
                nights: this.searchNights,
                showWelfare: this.searchShowWelfare,
                triggerSearch: true,
            }

            
            this.mixinSendMutation('setFocus', this.searchFocus)
            this.mixinSendMutation('setBudget', this.searchBudget)
            this.mixinSendMutation('setNights', this.searchNights)
            this.mixinSendMutation('setShowWelfare', this.searchShowWelfare)

            /*if(this.$route.name == 'shop' && (this.searchFocus != '-' || this.searchBudget != '0-0')){

              this.mixinGoTo('shop', {searchParameters: parameters})
              this.submitSearch()
              return

            }*/

            this.$emit('submit-boxset',parameters)
        },
        disableSearchButton()
        {
            if (this.searchFocus == null) return true
            //if (this.budget == null) return true
            //if (this.nights == null) return true
            return false
        },
    },

    mounted()
    {
        //this.mixinSendMutation('setFocus', null)

        /*if(this.$route.name == 'shop'){

          //this.mixinSendMutation('setFocus', '-')
          //this.mixinSendMutation('setBudget', '0-0')

          if(this.triggerSearch) this.submitSearch()

        }else if (!this.disableSearchButton()){

            if(this.triggerSearch) this.submitSearch()

        }*/


        //this.updateBudgetOptions()

        if (!this.disableSearchButton()){
          if(this.triggerSearch) this.submitSearch()
        }

    }, // mounted
}
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../../scss/_custom.scss';

    #searchBoxset
    {
        //border:1px solid red;
        display:flex;
        flex-direction: column;
        padding-top:$grid-gutter-width/6;
        padding-bottom:$grid-gutter-width/3;
        @include media-breakpoint-up(lg)
        {
            flex-direction: row;
        }
        .boxsetFormItem
        {
            display:flex;
            flex-direction: column;
            justify-content: flex-end;
            flex:1;
            &:not(:last-child)
            {
                margin-bottom:$grid-gutter-width/3;
                @include media-breakpoint-up(lg)
                {
                    margin-bottom: 0;
                    margin-right:$grid-gutter-width/2;
                }
            }
        }
        #search-boxset-welfare
        {
            align-items: center;
        }
        label
        {
            font-size: 14px;
            //text-transform: uppercase;
            margin-bottom:0;
        }
        .boxsetFormItem:not(#search-boxset-welfare)
        {
            label
            {
                font-weight: 600;
            }
        }
        select
        {
            //text-transform:uppercase;
            font-size: 14px;
        }
        #search-boxset-buttons
        {
            @include media-breakpoint-up(lg)
            {
                max-width: 200px;
            }
        }
        #search-boxset-welfare
        {
            .input-with-icon
            {
                height:100%;
                display:flex;
                align-items:center;
                &>label
                {
                    padding-right: .8rem;
                    line-height: 120%;
                    text-transform: none;
                }
            }
            @include media-breakpoint-up(md)
            {
                margin-top:24px;
            }
        }

        @include media-breakpoint-down(lg)
        {
            .custom-switch
            {
                transform:scale(0.85);
            }
        }

        @include media-breakpoint-only(md)
        {
            flex-direction: row;
            flex-wrap: wrap;
            .boxsetFormItem
            {
                min-width:calc(50% - 1rem);
                max-width:calc(50% - 1rem);
                margin:auto;
                &:nth-child(1),
                &:nth-child(3)
                {
                    margin-right:0.5rem;
                }
                &:nth-child(2),
                &:nth-child(4)
                {
                    margin-left:0.5rem;
                }
            }
        }
    }
</style>